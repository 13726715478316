<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/alarmes" class="mr-2" />
        <v-btn
          color="red"
          class="white--text"
          :loading="carregandoPdf"
          @click="carregarPdf"
        >
          <v-icon class="mr-2"> mdi-file-pdf </v-icon>
          PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="carregando"
      color="primary"
      indeterminate
      class="mt-4"
    />

    <v-row v-else align="center" justify="center" class="mt-2">
      <v-col cols="12" align="center" justify="center"
        ><h2 class="ocorrenciaTitulo">
          Histórico de passagem dos veículos da ocorrência
        </h2></v-col
      >
      <v-col cols="12" align="center" justify="center">
        <v-btn
          @click="buscarDetalhesLeitura()"
          :loading="carregandoDetalhesLeitura"
        >
          Detalhes da leitura do Veículo
        </v-btn>
      </v-col>
      <dialog-detalhes-leitura
        v-if="dialogDetalhesLeitura"
        v-model="dialogDetalhesLeitura"
        :leitura="detalhesLeitura"
      />

      <v-col cols="12" md="8">
        <v-icon class="camera-icone"> mdi-cctv </v-icon>
        <v-timeline dense>
          <v-timeline-item
            v-for="(item, key) in historico"
            :key="key"
            small
            right
            class="pb-1"
          >
            <v-card class="elevation-2">
              <v-card-text>
                <p class="ma-0">
                  <span class="body-1">
                    {{ item.data_registro | data("DD/MM/YYYY HH:MM:ss") }}
                  </span>
                  -
                  <span class="body-2">
                    {{ item.descricao }}
                  </span>
                </p>

                <template v-if="item.historico">
                  <p class="body-1 ma-0 mt-2 font-weight-bold">
                    A leitura da placa confere com a imagem?
                  </p>
                  <p class="body-1 ma-0">
                    {{ item.informe_placa }}
                  </p>

                  <p class="body-1 ma-0 mt-2 font-weight-bold">Histórico</p>
                  <p class="body-1 ma-0">
                    {{ item.historico }}
                  </p>
                </template>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiAlertas from "@/api/alertas";
import apiLeituras from "@/api/leituras";

export default {
  components: {
    DialogDetalhesLeitura: () =>
      import("@/components/dialog/leitura/DialogDetalhesLeitura"),
  },

  props: {
    teste: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      carregando: false,
      carregandoPdf: false,
      historico: null,
      leituraId: null,
      detalhesLeitura: null,
      dialogDetalhesLeitura: false,
      carregandoDetalhesLeitura: false,
    };
  },

  mounted() {
    this.carregar();
  },

  methods: {
    async carregar() {
      try {
        this.carregando = true;

        const resposta = await apiAlertas.historico(this.$route.params.id);

        this.historico = resposta.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },

    async carregarPdf() {
      try {
        this.carregandoPdf = true;

        const resposta = await apiAlertas.exportar(this.$route.params.id);

        const linkSource = `data:application/pdf;base64,${resposta.data.pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `alarme_${this.$route.params.id}`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoPdf = false;
      }
    },
    async buscarDetalhesLeitura() {
      try {
        if (!this.$temPermissao("leituras-visualizacao")) {
          return;
        }
        this.carregandoDetalhesLeitura = true;
        const dadosAlerta = await apiAlertas.buscarAlerta(
          this.$route.params.id
        );

        this.leituraId = dadosAlerta.data.leitura_id;

        const dados = await apiLeituras.detalhes(this.leituraId);

        this.detalhesLeitura = dados.data;

        this.dialogDetalhesLeitura = true;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoDetalhesLeitura = false;
      }
    },
  },
};
</script>
<style scoped>
.camera-icone {
  font-size: 50px;
  position: relative;
  left: 20px;
  bottom: 10px;
  color: #1976d2;
}
.ocorrenciaTitulo {
  font-size: 1.5em;
  font-weight: 500;
}
</style>